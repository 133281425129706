import React from "react";const WhoAreWe = () => {
   return (
      <div id="whoarewe">
         <div className="container">
            <h1 id="mini-title">Кто мы</h1>
            <div className="whoarewe">
               <h1 id="title">
                  Институт
                  <br />
                  <span>KazSustainDev</span>
               </h1>
               <div className="p">
                  <p>
                     Институт KazSustainDev – это ключевой игрок в продвижении
                     принципов ESG (Environmental, Social, and Governance) и
                     Целей устойчивого развития (ЦУР) в Казахстане. Наш институт
                     стремится обеспечить экономическое процветание и улучшение
                     качества жизни для нынешних и будущих поколений, создавая
                     платформу для объединения ведущих профессионалов в области
                     устойчивого развития, экологической безопасности и
                     корпоративной ответственности.
                  </p>
                  <p>
                     KazSustainDev стал центром для тех, кто видит будущее
                     Казахстана устойчивым, стабильным и экологически
                     безопасным. В нашем институте мы верим, что каждый шаг на
                     пути к устойчивости – это вклад в более светлое завтра для
                     всех.
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default WhoAreWe;
