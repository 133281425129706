import React from "react";
const Sight = () => {
   return (
      <div id="sight">
         <div className="container">
            <h1 id="mini-title">Видение</h1>
            <div className="sight">
               <h1 id="title">
                  <span>KazSustainDev</span> <br />
                  стремится создать <br />
                  условия
               </h1>
               <div className="p">
                  <p>
                     KazSustainDev стремится создать условия, при которых
                     Казахстан станет страной с устойчивым инвестиционным
                     климатом, который будет привлекателен для ответственного
                     бизнеса и стимулирует развитие новых технологий и практик в
                     ESG. Мы стремимся видеть Казахстан ведущим примером в
                     Центральной Азии, где принципы ESG и ЦУР реализуются на
                     уровне бизнеса, государственной политики и общества в
                     целом.
                  </p>
                  <p>
                     Создавая благоприятные условия для устойчивого будущего, мы
                     укрепляем уверенность инвесторов и партнеров в стабильности
                     и перспективности Казахстана.
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Sight;
