import React from "react";
const BgGuide = () => {
   return (
      <div id="bg">
         <div className="container">
            <div className="img">
               <img src="img/bg-guide.png" alt="" />
            </div>
         </div>
      </div>
   );
};

export default BgGuide;
