import React from "react";
const Services = () => {
   return (
      <div id="services">
         <div className="container">
            <div className="services">
               <div className="item">
                  <div className="left">
                     <img src="img/logo-grey.png" alt="" />
                     <h1 id="title">
                        <span>01</span>
                     </h1>
                  </div>
                  <div className="right">
                     <h1 id="title">
                        Техническое регулирование и стандартизация <br />
                        <span>ESG и устойчивого развития</span>
                     </h1>
                     <p>
                        Институт координирует работу технического комитета по
                        стандартизации, где ведущие эксперты и представители
                        рынка совместно разрабатывают и внедряют стандарты в
                        сфере ESG и устойчивого развития. Наши заседания
                        направлены на создание нормативной базы, способствующей
                        улучшению практик в корпоративной среде и поддержке
                        экологически ответственных инициатив.
                     </p>
                  </div>
               </div>
               <div className="item">
                  <div className="left">
                     <img src="img/logo-grey.png" alt="" />
                     <h1 id="title">
                        <span>02</span>
                     </h1>
                  </div>
                  <div className="right">
                     <h1 id="title">
                        Оценка <span>ESG-отчетов</span>
                     </h1>
                     <p>
                        Мы предоставляем независимую и профессиональную оценку
                        ESG-отчетов компаний, чтобы повысить их прозрачность и
                        подотчетность перед заинтересованными сторонами. Этот
                        процесс помогает компаниям корректировать и улучшать
                        свои стратегии в сфере устойчивого развития,
                        ориентируясь на лучшие международные стандарты.
                     </p>
                  </div>
               </div>
               <div className="item">
                  <div className="left">
                     <img src="img/logo-grey.png" alt="" />
                     <h1 id="title">
                        <span>03</span>
                     </h1>
                  </div>
                  <div className="right">
                     <h1 id="title">
                        Проведение мероприятий и инициатив по <br />
                        <span>развитию ESG и устойчивого развития</span>
                     </h1>
                     <p>
                        Мы предоставляем независимую и профессиональную оценку
                        ESG-отчетов компаний, чтобы повысить их прозрачность и
                        подотчетность перед заинтересованными сторонами. Этот
                        процесс помогает компаниям корректировать и улучшать
                        свои стратегии в сфере устойчивого развития,
                        ориентируясь на лучшие международные стандарты.
                     </p>
                  </div>
               </div>
               <div className="item">
                  <div className="left">
                     <img src="img/logo-grey.png" alt="" />
                     <h1 id="title">
                        <span>04</span>
                     </h1>
                  </div>
                  <div className="right">
                     <h1 id="title">
                        Мы стремимся к созданию{" "}
                        <span>
                           экологически и <br />
                           социально ответственного общества
                        </span>
                     </h1>
                     <p>
                        Мы стремимся к созданию экологически и социально
                        ответственного общества, которое соблюдает высокие
                        стандарты корпоративного управления и активно вносит
                        вклад в устойчивое будущее Казахстана.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Services;
