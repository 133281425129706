import React from "react";
const Potential = () => {
   return (
      <div id="potential">
         <div className="container">
            <div className="potential">
               <h1 id="title">
                  Для мира с большим{" "}
                  <span>потенциалом и меньшим воздействием</span>
               </h1>
               <p>
                  Казахский институт ESG и устойчивого развития — это ведущая
                  казахстанская организация, направленная на продвижение
                  принципов устойчивого развития и внедрение международных
                  стандартов ESG (экология, социальная ответственность,
                  корпоративное управление) в деловую и общественную практику
                  страны. Мы видим свою задачу в формировании ответственного
                  подхода к развитию, способствующего укреплению экономики,
                  улучшению качества жизни и защите окружающей среды. Наш
                  институт объединяет профессионалов из разных областей, включая
                  экологов, социальных специалистов, экспертов в области
                  управления и экономики. Вместе мы разрабатываем, внедряем и
                  продвигаем инициативы, которые помогают организациям улучшить
                  свои показатели в сфере устойчивости и социальной
                  ответственности. Наша деятельность охватывает научные
                  исследования, образовательные программы и консультативные
                  услуги для бизнеса и государственных структур.
               </p>
            </div>
         </div>
      </div>
   );
};

export default Potential;
