import React from "react";
const BgActivity = () => {
   return (
      <div id="bg">
         <div className="container">
            <div className="img">
               <img src="img/bg-activity.png" alt="" />
            </div>
         </div>
      </div>
   );
};

export default BgActivity;
