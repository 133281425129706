import React from "react";
const Mission = () => {
   return (
      <div id="mission">
         <div className="container">
            <h1 id="mini-title">Наша миссия</h1>
            <div className="mission">
               <h1 id="title">
                  Наша миссия – <br />сделать <span>ESG и ЦУР</span> <br />не просто идеями
               </h1>
               <div className="p">
                  <p>
                  Мы стремимся объединить сильнейших в области охраны окружающей среды, социальной ответственности и управления из разных отраслей Казахстана. Через работу технического комитета KazSustainDev формирует стандарты и разрабатывает предложения для законодательных изменений, которые ускорят внедрение ESG и ЦУР. Наш институт нацелен на создание прочных основ, обеспечивающих надежные стандарты, которые поддержат Казахстан в его движении к устойчивому развитию.
                  </p>
                  <p>
                  Наша миссия – сделать ESG и ЦУР не просто идеями, но реальной частью корпоративной и государственной политики, улучшая экономическую и экологическую перспективу страны.
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Mission;
